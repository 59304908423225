import React from 'react';
import "./about.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const About = () => {
    const data = [
        {
            name: "About Us",
            desc: "Welcome to NoBounds Dev, where creativity meets precision. NoBounds Dev is a website design and programming buisness ran by myself, Nathan Benayguev. I'm a University student working on a BSc in Computer Science, and I started this little venture to blend my passion for web design with real-world experience."
        },
        {
            name: "Who I Am",
            desc: "As a current university student, juggling university life and running a web design company might sound hectic, but for me, it's all about turning my passion into something meaningful."
        },
        {
            name: "My Mission",
            desc: "At NoBounds, my mission is simple: to bring your ideas to life online. I believe in the power of creativity and technology to build beautiful, functional websites that help people."
        },
        /*{
            name: "Why Choose Me?",
            desc: "Passion and Dedication: I love what I do, and I put my heart into every project. Your website will be crafted with care and attention to detail.",
            desc1: "Cutting-Edge Skills: As a current computer science student, I'm always learning the latest trends and technologies. Your website will be up-to-date with the best practices in the industry.",
            desc2: "Personal Touch: Working with me means you'll get a personalized approach. I take the time to understand what you need and tailor my services to match your vision.",
            desc3: "Innovative Design: I push the limits to create websites that are not only stunning but also user-friendly and responsive on all devices."
        },*/
        {
            name: "Let's Build Something Great",
            desc: "I'm excited to help you build a compelling online presence. Whether you're a small business, an entrepreneur, or just someone looking to showcase their personal brand, NoBounds Dev is here to help you achieve your digital goals."
        }
    ]

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      };

  return (
    <div className="about-container">
        <Helmet>
            <title>About NoBounds: Professional Web Design with a Personal Touch</title>
            <meta name="description" content="NoBounds blends creativity and expertise to deliver top-tier web design solutions. Discover our passion for seamless digital experiences." />
            <link rel="canonical" href="http://nobounds.dev/about-us" />
        </Helmet>

        <Slider className='slider' {...settings}>
            {data.map((d, index) => (
                    <div className={`about-slide about-slide-${index}`} key={index}>
                        <div className="about-title">
                            <h2>{d.name}</h2>
                        </div>
                        <div className="about-desc">
                            <p>{d.desc}</p>
                            {d.desc1 && <p>{d.desc1}</p>}
                            {d.desc2 && <p>{d.desc2}</p>}
                            {d.desc3 && <p>{d.desc3}</p>}
                        </div>
                    </div>
                ))}
        </Slider>
        <Link to="/portfolio">Our Portfolio</Link>
    </div>
  );
};

export default About;
