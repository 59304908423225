import Domain from "./Domain";
import "./home.css";
import { Link } from 'react-router-dom';
import React from "react";

const Home = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'instant' // Optional smooth scrolling
        });
    }; 

    return ( 
    <div>
        <div className="homeWrap">
            <div className="shader">
                <div className="homeTitle">
                    <h1 className="homeHeader">Creativity has NoBounds.</h1>
                    <div className="homeWrap2">
                        <Link onClick={scrollToTop} className="homeCreate" to="/services">Create Now</Link>
                        <b>Unleash Your Ideas Without Breaking the Bank</b>
                    </div>
                </div>
            </div>
        </div>
        <Domain/>
        <div className="gar">
            <div  class="hoContainer">
                <header>
                    <h2>Website Guarantees at NoBounds Dev</h2>
                </header>
                <section class="features">
                    <div class="feature">
                        <h2>Efficient Design</h2>
                        <p>NoBounds uses React JS framework for swift loading times</p>
                    </div>
                    <div class="feature">
                        <h2>Reliable Hosting</h2>
                        <p>Every project at NoBounds is guaranteed 99.95% uptime at all times</p>
                    </div>
                    <div class="feature">
                        <h2>SEO is Prioritized</h2>
                        <p>Every website made at NoBounds is optimized to place high in Google's Search Engine</p>
                    </div>
                    <div class="feature">
                        <h2>Encrypted Security</h2>
                        <p>Secure Sockets Layer (SSL) prevents hackers from seeing or stealing any information transferred</p>
                    </div>
                    <div class="feature">
                        <h2>Flexible Design</h2>
                        <p>All design ideas and concepts are considered and implemented to give your website your touch</p>
                    </div>
                    <div class="feature">
                        <h2>All Devices</h2>
                        <p>NoBounds ensures compatibility between all devices of different sizes and operating systems</p>
                    </div>
                </section>
                <Link onClick={scrollToTop} className="packs" to="/services">Get Started</Link>
            </div>
        </div>
    </div>
    );
}
 
export default Home;