import "./portfolio.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const Portfolio = () => {
    const shinyclean =[
        {
            cap: "Welcome to Shiny Clean Detailing! This website is an example of NoBounds Dev's buisness package with many detailed pages, stunning luxury designs and technical features.",
            picPath: "ShinyMain.jpg"
        },
        {
            cap: "To display Shiny Clean's clay bar treatments, ceramic coatings, interior cleaning... an expandable gallery is used that contours to fit every device effectivly.",
            picPath: "ShinyGallery.jpg"
        },
        {
            cap: "A variety of effective packages from Shiny Clean Detailing are displayed proffessionally with an appropriate colour theme and neat organization of services offered",
            picPath: "ShinyPackages.jpg"
        },
        {
            cap: "On top of many packages, individual add-ons are displayed through simple tables with prices varying on car size/type. This table scrolls horiztonally on smaller devices.",
            picPath: "ShinyAddons.jpg"
        },
        {
            cap: "By utilizing high-quality, copyright-licensed images, the About page is both sleek and informative, ensuring legal compliance while effectively showcasing the business.",
            picPath: "ShinyAbout.jpg"
        },
        {
            cap: "An auto quote feature is integrated to support all car models using a back-end carAPI while also including all Shiny Clean Detailing packages and add-ons",
            picPath: "ShinyQuote.jpg"
        },
        {
            cap: "After submission, a price estimate including a service list is displayed while a contact form is displayed to contact Shiny Clean for an appointment and/or other inquires",
            picPath: "ShinyQuote2.jpg"
        }
    ]

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      };

    return ( 
    <div className="portfolio">
        <Helmet>
            <title>NoBounds Portfolio: Showcasing Our Web Design Excellence</title>
            <meta name="description" content="Explore the NoBounds portfolio to see our web design excellence. Discover how we blend creativity and expertise to create seamless digital experiences." />
            <link rel="canonical" href="http://nobounds.dev/portfolio" />
        </Helmet>
        <h1>NoBounds Dev's Portfolio</h1>
        <div className="portfolioHead">
            <img className="ShinyLogo" src="/ShinyLogo.png"></img>
        </div>
        <div className="port-slider">
            <Slider className='sliderPort' {...settings}>
                {shinyclean.map((d, index) => (
                        <div className={`port-slide port-slide-${index}`} key={index}>
                            <div className="port-desc">
                                <b>{d.cap}</b>
                            </div>
                            <div className="port-img">
                                <img src={d.picPath}></img>
                            </div>
                        </div>
                    ))}
            </Slider>
        </div>
        <a target="blank" href="https://shinycleandetailing.com">Shinycleandetailing.com</a>

    </div>
 );
}
 
export default Portfolio;