import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Navbar from './Navbar';
import Home from './Home';
import Services from './Services';
import Portfolio from './Portfolio';
import About from './About';
import Contact from './Contact';
import Blog from "./Blog";
import {Helmet} from "react-helmet";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        timeout={300}
        classNames="fade"
      >
        <Routes location={location}>
          <Route path="/" element={<Home/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/about-us" element={<About/>} />
          <Route path="/portfolio" element={<Portfolio/>} />
          <Route path="/contact-us" element={<Contact/>} />
          <Route path="/blog" element={<Blog/>}></Route>
          <Route path="*" element={<Home />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}


function App() {
  return (
    <div className="App">
      <div className="content">
        <Router>
          <Navbar />
          <AnimatedRoutes />
        </Router>
      </div>
    </div>
  );
}

export default App;
