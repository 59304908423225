import "./services.css"
import { Link } from 'react-router-dom';
import React from 'react';
import './App.css';
import Faq from 'react-faq-component';

const packages = [
  {
    category: "Website Design and Development Packages: ",
    tiers: [
      {
        name: "Streamlined ($180)",
        features: [
          "3 days",
          "-1 day (+$60)",
          "4 pages",
          "Swift Run Times",
          "Basic responsive design",
          "Contact form",
          "Social media integration",
          "Basic SEO setup"
        ]
      },
      {
        name: "Core ($350)",
        features: [
          "10 days",
          "-4 day (+$130)",
          "4-7 pages",
          "Optimized Run Times",
          "Custom responsive design",
          "Contact form",
          "Social media integration",
          "Full SEO setup",
          "Google Analytics",
        ]
      },
      {
        name: "Business Starter ($800)",
        features: [
          "20 days",
          "-7 day (+$180)",
          "7-10 pages",
          "Fully optimized Run Times",
          "Custom responsive design",
          "Contact form",
          "Social media integration",
          "Advanced SEO setup",
          "Google Analytics",
          "Automatic quote integration",
        ]
      },
      {
        name: "E-Com Package ($1600)",
        features: [
          "30 days",
          "-10 day (+$300)",
          "10+ pages",
          "Fully optimized Run Times",
          "Custom design and user experience",
          "Contact form",
          "Social media integration",
          "Advanced SEO and marketing tools",
          "Automatic quote features",
          "Online store setup",
          "Multiple payment gateways",
      ]
      }
    ]
  },
  {
    category: "Website Maintenance and Re-Design Plans: ",
    tiers:[
      {
        name: "Basic maintenance (5$/mo)",
        features: [
          "99.95% Uptime",
          "1 format/design/info re-work Monthly",
        ]
      },
      {
        name: "Standard maintenance (10$/mo)",
        features: [
          "99.95% Uptime",
          "1 format/design re-work Monthly",
          "3 informational updates Monthly",
          "Malware Removal"
        ]
      },
      {
        name: "Advanced maintenance (15$/mo)",
        features: [
          "99.95% Uptime",
          "1 format/design re-work Monthly",
          "3 informational updates Monthly",
          "Malware Removal",
        ]
      },
      {
        name: "Ultimate maintenance (25$/mo)",
        features: [
          "99.95% Uptime",
          "2 format/design re-work Monthly",
          "4 informational updates Monthly",
          "Malware Removal",
          "1 New Feature Monthly"
        ]
      }
    ]
  }
  // Add more packages similarly...
];

const PackageList = ({ category, tiers }) => (
  <div className="package">
    <h2>{category}</h2>
    <div className="listing">
      {tiers.map((tier, index) => (
         <div key={index} className={`package-${index}`}>
          <h3>{tier.name}</h3>
          <ul>
            {tier.features.map((feature, idx) => (
              <li key={idx}>{feature}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
);

const data = {
  title: "Frequently Asked Questions",
  rows: [
    {
      title: "What if what I want is not listed in a package or slightly different?",
      content: "Fill our Contact Form and NoBounds will provide you with a free quote that either follows the standard packages or is modified to your liking."
    },
    {
      title: "How does payment work at NoBounds?",
      content: "First 25% of the project's cost must be deposited and once done, the rest must be transferred to deploy it."
    },
    {
      title: "What to do if not satisfied with the theme or user experience?",
      content: "If you are not satisfied with the user experience, theme or anything else, you are entitled to 2 design re-works. Re-Works might extend deadlines."
    },
    {
      title: "Does a bug fix count as a re-work?",
      content: "Absolutely not! In the odd case of a bug/glitch contact us and it will be worked on right away."
    },
    {
      title: "Are domains provided?",
      content: "NoBounds charges the lowest prices for availible domains purley depending on the domain value. Use our domain availiblity tool and then fill our contact form."
    },
    {
      title: "Are NoBounds Dev's prices and rates negotiable",
      content: "Prices are negotiable depending on factors such as price matching, custom requests (modifications to the package) or any other reasonable factors."
    }]
}

const Services = () => (
  <div className="servContainer">
    <h1>NoBounds Competitive Bundles</h1>
    <b>Choose from our simple yet effective website development bundles, all packages at NoBounds are entitled to free hosting without a maintenance plan (excluding domains)</b>
    <Link to="/about-us">More About NoBounds Dev</Link>
      <div className="packages">
          {packages.map((pkg, index) => (
          <PackageList key={index} category={pkg.category} tiers={pkg.tiers} /> 
        ))}
      </div> 
      <div className="faqWrap">
        <Faq className="faq"data={data}/>
      </div>
    <Link className="serviceToContact" to="/contact-us">Contact Us</Link>
  </div>
);

export default Services;
