import React, { useRef, useState } from 'react';
import './domain.css';

const searchDomain = (inputRef, setResult) => {
    const domain = inputRef.current.value;
    const apiKey = "at_BBn6x1uh51UurZeZYHodcootu6hzD";
  
    if (domain != "")
    {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `https://www.whoisxmlapi.com/whoisserver/WhoisService?apiKey=${apiKey}&domainName=${domain}`, true);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) 
        {
          if (xhr.status === 200) 
          {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xhr.responseText, "text/xml");
            const statusElement = xmlDoc.getElementsByTagName("status")[0];
            const status = (statusElement ? statusElement.textContent : "Status not found").toLowerCase();

            console.log(status);

            if (status.includes("prohibited"))
            {
              setResult(domain + " is unavailable");
            }
            else if (status.includes("not found"))
            {
              setResult(domain + " is available");
            }
            else{
              setResult(status);
            }
          } 
          else 
          {
            setResult("Unknown error");
          }
        }
      };
      xhr.send();
    }
  };
  
const Domain = () => {
  const inputRef = useRef();
  const [result, setResult] = useState("");

  return (
    <div className="domainWrap">
      <h2>Find a Domain for your Website </h2>
      <div className="form__group field">
        <input
          type="input"
          className="form__field"
          placeholder="Name"
          name="name"
          id="name"
          ref={inputRef}
          required
        />
        
        <label htmlFor="name" className="form__label">Domain Name</label>
      </div>
      <button className="search" onClick={() => searchDomain(inputRef, setResult)}>Search</button>
      {!(result==="") && <div id="result">
        <b>{result + " "}<a href="/contact-us">Contact Us</a></b>
    </div>}
    </div>
  );
};

export default Domain;
