import * as FormData from 'form-data';
import Mailgun from 'mailgun.js';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import "./contact.css";
import {Helmet} from "react-helmet";

const Contact = () => {
    const mailgun = new Mailgun(FormData);
    const mg = mailgun.client({ username: 'api', key: process.env.MAILGUN_API_KEY || '594d1c055943ebaebc3840a2a580797c-fe9cf0a8-a80ea456' });

    const navigate = useNavigate();
    const [msg, setMsg] = useState("");

    const handleChange = (e) => {
        const textarea = e.target;
        setMsg(textarea.value);
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const personalData = {
            firstName: formData.get('firstname'),
            email: formData.get('email'),
            phone: formData.get('phone'),
            package: formData.get('package'),
            msg: formData.get('msg'),
        };

        console.log("Form Data:", personalData);

        const formDataString = `
            First Name: ${personalData.firstName}\n
            Email: ${personalData.email}\n
            Phone: ${personalData.phone}\n
            Package: ${personalData.package}\n
            Message: ${personalData.msg}`;

        try {
            await mg.messages.create('sandbox4f897cdec3cb42deb6b7d9eb3fa40cee.mailgun.org', {
                from: "NoBounds@NoBounds.dev",  // Replace with your authorized from address
                to: ["noboundsdevca@gmail.com"],
                subject: "Contact from: " + personalData.firstName,
                text: formDataString,  // Plain text version of the email
                html: `<b>${formDataString.replace(/\n/g, '<br>')}</b>`  // HTML version of the email
            });

            alert("Thank you for your interest in NoBounds Dev, Nathan Benayguev will get back to you shortly");
            navigate("/");
        } catch (error) {
            console.error("Error sending email:", error);
            alert("An error occurred while sending the message. Please try again.");
            navigate("/");
        }
    };

    return (
        <div className='contact'>
            <Helmet>
                <title>Contact NoBounds: Get in Touch for Web Design Services</title>
                <meta name="description" content="Reach out to NoBounds for top-tier web design services. We're here to help you create seamless digital experiences. Get in touch today!" />
                <link rel="canonical" href="http://nobounds.dev/contact-us" />
            </Helmet>

            <h1>Contact Us</h1>
            <b>Please contact us for any information such as a free quote, starting a website with NoBounds Dev, or any questions!</b>
            <form className='contact-form' onSubmit={handleSubmit}>
                <div className="group">
                    <input type="text" name="firstname" required />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label>Name</label>
                </div>

                <div className="group">
                    <input type="email" name="email" required />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label>Email</label>
                </div>

                <div className="group">
                    <input type="tel" name="phone" required />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label>Phone Number (optional)</label>
                </div>

                <div className='dropdown'>
                    <select className='select' name="package" required>
                        <option value="">Select Package</option>
                        <option value="Streamlined ($180)">Streamlined ($180)</option>
                        <option value="Core ($350)">Core ($350)</option>
                        <option value="Business Starter ($800)">Business Starter ($800)</option>
                        <option value="E-Com Package ($1600)">E-Com Package ($1600)</option>
                    </select>
                </div>

                <div className="group">
                    <textarea
                        name="msg"
                        value={msg}
                        onChange={handleChange}
                        rows="1"
                        required
                        style={{ overflow: 'hidden', resize: 'none' }}
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label>Message</label>
                </div>

                <div className='group'>
                    <button type="submit" className="submit-button">Submit</button>
                </div>
            </form>
        </div>
    );
}

export default Contact;
